import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectionService {
  ua: string = navigator.userAgent;

  isIOS(): boolean {
    return /iPad|iPhone|iPod|iOS/.test(this.ua) || this.getPlatform() === 'MacIntel';
  }

  isSafari(): boolean {
    return /Safari/.test(this.ua) && !this.isChrome();
  }

  isChrome(): boolean {
    return /Chrome/.test(this.ua);
  }

  isFirefox(): boolean {
    return /Firefox/.test(this.ua);
  }

  isAndroid(): boolean {
    return /Android/.test(this.ua);
  }

  getPlatform() {
    return navigator?.platform;
  }
}
