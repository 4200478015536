/* tslint:disable: ordered-imports*/
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

/* Modules */
import { AppCommonModule } from '@common/app-common.module';
import { NotificationsState } from '@modules/notifications/store/notifications.state';
import { NgxsModule } from '@ngxs/store';

/* Components */
import * as navigationComponents from './components';

/* Containers */
import * as navigationContainers from './containers';

/* Layouts */
import * as appCommonLayouts from './layouts';

/* Guards */
import * as navigationGuards from './guards';

/* Services */
import * as navigationServices from './services';
import { TourNgBootstrapModule } from 'ngx-ui-tour-ng-bootstrap';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { PgDashboardHeadComponent } from './components/pg-dashboard-head/pg-dashboard-head.component';
import { BreadcrumbsComponent } from '@modules/navigation/components/breadcrumbs/breadcrumbs.component';

@NgModule({
  declarations: [...navigationContainers.containers, ...navigationComponents.components, ...appCommonLayouts.layouts],
  exports: [...navigationContainers.containers, ...navigationComponents.components, ...appCommonLayouts.layouts, PgDashboardHeadComponent],
  imports: [
    CommonModule,
    RouterModule,
    AppCommonModule,
    TourNgBootstrapModule,
    NgxsModule.forFeature([NotificationsState]),
    MdbDropdownModule,
    PgDashboardHeadComponent,
    BreadcrumbsComponent,
  ],
  providers: [{ provide: 'window', useValue: window }, provideHttpClient(withInterceptorsFromDi())],
})
export class NavigationModule {
  static forRoot(): ModuleWithProviders<NavigationModule> {
    return {
      ngModule: NavigationModule,
      providers: [...navigationServices.services, ...navigationGuards.guards],
    };
  }
}
