import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageToolsService {
  cookieService = inject(CookieService);
  // The following cookie name is important because it's Google-predefined for the translation engine purpose
  ENGLISH_LOCALE: 'en' = 'en';
  SPANISH_LOCALE: 'es' = 'es';
  COOKIE_NAME: string = 'googtrans';
  COOKIE_EXPIRE: string = 'Fri, 31 Dec 9999 23:59:59 GMT';
  SUPPORTED_LANGUAGES: string[] = [this.ENGLISH_LOCALE, this.SPANISH_LOCALE];

  currentLanguage: WritableSignal<string> = signal('en');
  isSpanish: Signal<boolean> = computed(() => this.currentLanguage() === this.SPANISH_LOCALE);
  languageToSwitchTo: Signal<string> = computed<string>(() => (this.isSpanish() ? this.ENGLISH_LOCALE : this.SPANISH_LOCALE));

  translateBarClass: Signal<string> = computed(() => {
    const isSpanish = this.isSpanish();
    if (isSpanish) {
      return 'translate-bar-visible';
    } else {
      return '';
    }
  });

  constructor() {
    this.currentLanguage.set(this.getCurrentLanguage());

    this.startCookieListener();
  }

  getCurrentLanguage(): 'en' | 'es' {
    const googTransCookie = this.getCookie('googtrans');
    if (googTransCookie) {
      return this.getLanguageFromCookie(googTransCookie);
    } else {
      return this.ENGLISH_LOCALE;
    }
  }

  switchLanguageTo(language: 'en' | 'es'): void {
    this.currentLanguage.set(language);
    this.setGoogTransCookie(language);
  }

  private getLanguageFromCookie(googTransCookieValue: string): 'en' | 'es' {
    const parts = googTransCookieValue.split('/');
    if (parts.length <= 1) {
      return this.ENGLISH_LOCALE;
    }
    const language: string = parts[parts.length - 1];

    if (this.SUPPORTED_LANGUAGES.includes(language)) {
      return language as 'en' | 'es';
    } else {
      return this.ENGLISH_LOCALE;
    }
  }

  private setGoogTransCookie(language: 'en' | 'es'): void {
    this.deleteCookie('googtrans');
    if (language === this.SPANISH_LOCALE) {
      this.setCookie(this.COOKIE_NAME, '/en/es');
    }
  }

  private getCookie(name: string): string | undefined {
    return this.cookieService.get(name);
  }

  private setCookie(cname: string, cvalue: string) {
    document.cookie = `${cname}=${cvalue};path=/;domain=${environment.DOMAIN_WILDCARD};expires=${this.COOKIE_EXPIRE}`;
  }

  private deleteCookie(cname: string, path: string = '/') {
    this.cookieService.delete(cname, path);
    this.cookieService.delete(cname, path, window.location.hostname);
    this.cookieService.delete(cname, path, environment.DOMAIN_WILDCARD);
  }

  private startCookieListener() {
    setInterval(() => {
      if (this.currentLanguage() !== this.getCurrentLanguage()) {
        this.currentLanguage.set(this.getCurrentLanguage());
      }
    }, 500); // Check every second
  }
}
