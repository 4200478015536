import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Breadcrumb } from '@modules/navigation/models';
import { NavigationService } from '@modules/navigation/services';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'sb-breadcrumbs',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['breadcrumbs.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  breadcrumbs: Breadcrumb[] | undefined = [];

  constructor(public navigationService: NavigationService) {}
  ngOnInit() {
    this.subscription.add(
      this.navigationService.routeData$().subscribe((routeData) => {
        this.breadcrumbs = routeData.breadcrumbs;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
